import React from 'react';
import { SingleCaseStudy } from 'views/single-case-study';
import { useTuro } from 'views/single-case-study/hooks/useTuro';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import caseStudiesMessages from 'constants/translations/case-studies/index.json';
import messages from 'constants/translations/case-studies/turo.json';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const TuroPage = (props) => {
    const { location } = props;
    const { data } = useTuro();

    return (
        <Layout
            location={location}
            messages={{ ...messages, ...caseStudiesMessages }}
        >
            <SingleCaseStudy data={data} />
        </Layout>
    );
};

TuroPage.propTypes = {
    location: object.isRequired,
};

export default TuroPage;

export const Head = () => <SEO tags={TAGS.CASE_STUDIES_TURO} />;
